import countryListA2 from '../../../utilities/country.js'
const countryList = Object.keys(countryListA2)

export default {
  cenik: {
    columns: [
      'vyrobce:!ASCIIU(2,16)',
      'znacka:!ASCII(2,5)',
      'skupZboz:!ASCII(2,5)',
      'typ:!ASCII(2,5)',
      'ean:!EANS',
      'nazev:!STRING(256)',
      'rada:!ASCII(1,5)',
      'velikost:UFLOAT',
      'velikost_mj:ENUM(mj)',
      'upresneni:ASCII(1,5)?',
      'bqt:UINT',
      'lqt:UINT',
      'pqt:UINT',
    ],
    enums : {
      mj: ['ml', 'g', 'pcs']
    },
    hasHeader: true,
    computed: {
      kod: row => {
        let out = ''

        if (row.velikost && row.velikost_mj && row.velikost_mj.length) {
          let rsize = Math.round(row.velikost)
          if (rsize >= 1000) {
            rsize = [Math.ceil(rsize / 1000), rsize % 1000]
            const joiner = row.velikost_mj === 'ml' ? 'L' : 'K'
            rsize = rsize.join(joiner)
          }
          out = `${rsize}`.substr(0,3).toUpperCase()
        }

        if (row.upresneni && row.upresneni.length) {
          out += row.upresneni
        }

        if (!out.length) {
          out = '000'
        }

        return `${row.znacka}.${row.rada}.${row.typ}.${out}.${row.skupZboz}`
      }
    },
    virtual: {
      upresnit: row => {
        let out = ''

        if (row.velikost && row.velikost_mj && row.velikost_mj.length) {
          let rsize = Math.round(row.velikost)
          if (rsize > 1000) {
            rsize = [Math.ceil(rsize / 1000), rsize % 1000]
            const joiner = row.velikost_mj === 'ml' ? 'L' : 'K'
            rsize = rsize.join(joiner)
          }
          out = `${rsize}`.substr(0,3).toUpperCase()
        }

        if (row.upresneni && row.upresneni.length) {
          out += row.upresneni
        }

        if (!out.length) {
          return '000'
        }

        return out
      }
    }
  },
  adresar: {
    columns: [
      'nazev:!STRING(64)',
      'kod:!ASCIIU(2,16)',
      'typ:!ENUM(typ)',
      'ic:STRING(32)',
      'dic:STRING(32)',
      'stat:!ENUM(stat)',
      'ulice:STRING(256)',
      'mesto:STRING(256)',
      'psc:STRING(16)',
      'email:STRING(256)',
      'mobil:STRING(256)',
      'poznam:STRING',
    ],
    enums: {
      stat: countryList,
      typ: ['odberDodav', 'odberatel', 'dodavatel', 'vyrobce']
    },
    computed: {
      typVztahuK: row => `typVztahu.${row.typ}`
    }
  },
  znacka: {
    columns: [
      'nazev:!STRING(64)',
      'kod:!ASCII(2,5)'
    ]
  },
  skupina: {
    columns: [
      'nazev:!STRING(64)',
      'nazevA:!STRING(64)',
      'kod:!ASCII(2,5)'
    ]
  },
  typ: {
    columns: [
      'nazev:!STRING(64)',
      'nazevA:!STRING(64)',
      'kod:!ASCII(2,5)'
    ]
  },
  rada: {
    columns: [
      'znacka:!ASCII(2,5)',
      'nazev:!STRING(64)',
      'kod:!ASCII(1,5)'
    ]
  }
}
