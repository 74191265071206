<template>
  <DataTable
    :value="workbook.cenik"
    dataKey="kod"
    :resizableColumns="true"
    columnResizeMode="expand"
    class="p-datatable-sm"
    responsiveLayout="scroll"
    v-model:filters="filters"
    filterDisplay="menu">
    <template #header>
      <div class="p-d-flex p-jc-between">
        <Button type="button" icon="pi pi-filter-slash" label="Clear" class="p-button-outlined" @click="clearFilter()"/>
        <div class="p-formgroup-inline">
          <span class="p-input-icon-left">
            <i class="pi pi-search" />
            <InputText v-model="filters['global'].value" placeholder="Keyword Search" />
          </span>
        </div>
      </div>
    </template>
    <Column header="#" :style="{width:'40px'}">
      <template #body="slotProps">
        {{ slotProps.index + 2 }}
      </template>
    </Column>
    <Column header="Status" :style="{width:'60px'}">
      <template #body="slotProps">
        <RowStatus :row="slotProps.data" @toggle-update="toggleUpdate(slotProps.data)" />
      </template>
    </Column>
    <Column field="kod" header="Code"></Column>
    <Column field="vyrobce" header="Manufacturer"></Column>
    <Column field="znacka" header="Brand"></Column>
    <Column field="skupZboz" header="Group"></Column>
    <Column field="typ" header="Type"></Column>
    <Column header="EAN(s)">
      <template #body="slotProps">
        <div
          v-if="slotProps.data.ean"
          v-tooltip="[...slotProps.data.ean].splice(1,slotProps.data.ean.length - 1).join('\n')"
          >{{ slotProps.data.ean[0] }}</div>
      </template>
    </Column>
    <Column field="nazev" header="Name"></Column>
    <Column field="rada" header="Line"></Column>
    <Column field="velikost" header="Size"></Column>
    <Column field="velikost_mj" header="Unit"></Column>
    <Column field="upresneni" header="Spec."></Column>
    <Column field="boxQTY" header="Box QT"></Column>
    <Column field="layerQTY" header="Layer QT"></Column>
    <Column field="palletQTY" header="Pallet QT"></Column>
  </DataTable>
</template>

<script>
import {FilterMatchMode,FilterOperator} from 'primevue/api';
import RowStatus from '../RowStatus.vue'

export default {
  props: ['workbook', 'flexi', 'selection'],
  emits: ['toggleUpdate'],
  data() {
    return {
      filters: null
    }
  },
  created() {
    this.initFilters()
  },
  methods: {
    clearFilter() {
			this.initAddressFilters()
		},
		initFilters() {
			this.filters = {
				global: {value: null, matchMode: FilterMatchMode.CONTAINS},
				status: {operator: FilterOperator.OR, constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]}
			}
		},
    toggleUpdate(row) {
      this.$emit('toggleUpdate', row)
    }
  },
  components: {
    RowStatus
  }
}
</script>

<style scoped>
</style>
