<template>
	<div class="p-grid">
		<div class="p-col-8">
			<h1>Price List export</h1>
		</div>
		<div class="p-col-4" align="right">
			<a :href="`${publicPath}pricelist_import_template.xlsx`" class="p-button p-component" download>
				<span class="pi pi-download p-button-icon p-button-icon-left"></span>
				<span class="p-button-label">Pricelist Import Template</span>
			</a>
		</div>
	</div>
	<div class="p-grid">
		<div class="p-col-12">

			<div class="card dropfile" v-if="status === 'empty'">
				<ProcessXLSX :rules="workbookRules" @parsed="workbookParsed" />
			</div>

			<div class="card" v-if="status === 'processing'">
				<p>Processing selected Excel Sheet</p>
				<ProgressBar :value="processingProgress" />
			</div>

			<div class="card" v-if="status === 'ready' || status === 'done'">
				<div class="p-grid">
					<div class="p-col-6">
						<h5 v-if="status === 'ready'">Excel file content</h5>
						<h5 v-if="status === 'done'"><b>Import done - results</b></h5>
					</div>
					<div class="p-col-6" align="right">
						<ConfirmDialog></ConfirmDialog>
						<Button
							class="p-button-danger p-mr-2"
							icon="pi pi-times"
							label="Clear"
							@click="confirmClearing()"></Button>
						<Button
							v-if="status === 'ready'"
							class="p-button-success  p-mr-2"
							icon="pi pi-check"
							label="Import"
							@click="importData()"></Button>
						<Button
							v-if="status === 'done' || status === 'ready'"
							class="p-button-primary p-mr-2"
							icon="pi pi-download"
							label="Download errored rows"
							@click="downloadFailed()"></Button>
					</div>
				</div>
				<TabView>
					<TabPanel>
						<template #header>
							<IconTabHeader icon="pi-calendar" label="Address Book" :disabled="!workbook.adresar" />
						</template>
						<ImportAdresarTable :workbook="workbook" :flexi="flexi" @toggle-update="toggleUpdate" />
					</TabPanel>

					<TabPanel>
						<template #header>
							<IconTabHeader icon="pi-dollar" label="Brands" :disabled="!workbook.znacka" />
						</template>
						<ImportZnackyTable :workbook="workbook" :flexi="flexi" @toggle-update="toggleUpdate" />
					</TabPanel>

					<TabPanel>
						<template #header>
							<IconTabHeader icon="pi-sitemap" label="Groups" :disabled="!workbook.skupina" />
						</template>
						<ImportSkupinyTable :workbook="workbook" :flexi="flexi" @toggle-update="toggleUpdate" />
					</TabPanel>

					<TabPanel>
						<template #header>
							<IconTabHeader icon="pi-share-alt" label="Types" :disabled="!workbook.typ" />
						</template>
						<ImportTypyTable :workbook="workbook" :flexi="flexi" @toggle-update="toggleUpdate" />
					</TabPanel>

					<TabPanel>
						<template #header>
							<IconTabHeader icon="pi-heart" label="Lines" :disabled="!workbook.rada" />
						</template>
						<ImportRadyTable :workbook="workbook" :flexi="flexi" @toggle-update="toggleUpdate" />
					</TabPanel>

					<TabPanel>
						<template #header>
							<IconTabHeader icon="pi-book" label="Pricelist" :disabled="!workbook.cenik" />
						</template>
						<ImportCenikTable :workbook="workbook" :flexi="flexi" @toggle-update="toggleUpdate" />
					</TabPanel>
				</TabView>
			</div>

			<div class="card" v-if="status === 'importing'">
				<p>Importing data</p>
				<ProgressBar :value="importProgress" />
			</div>

			<Dialog
				header="Import Pricelist"
				v-model:visible="importDialog"
				:modal="true"
				:breakpoints="{'1200px': '75vw', '840px': '100vw'}"
				:style="{width: '60vw'}">
				<p>You are trying import an import Excel Sheet with these values. Proceed?</p>
				<DataTable :value="toImport" dataKey="evidence" class="p-datatable-sm">
					<Column header="Evidence">
						<template #body="slotProps">
							<b>{{slotProps.data.evidence}}</b>
						</template>
					</Column>
					<Column field="columns.importFile" header="Import file total"></Column>
					<Column field="columns.new" header="New rows"></Column>
					<Column field="columns.error" header="Rows with error"></Column>
					<Column field="columns.duplicit" header="Duplicit rows"></Column>
					<Column header="Updated rows">
						<template #body="slotProps">
							{{slotProps.data.columns.update}}
						</template>
					</Column>
					<!-- <Column field="columns.update" header="Updated rows"></Column> -->
					<Column field="columns.flexi" header="Already in Flexi"></Column>
				</DataTable>
				<template #footer>
					<Button label="No" icon="pi pi-times" class="p-button-text" @click="importDialog = false"/>
					<Button label="Yes" icon="pi pi-check" autofocus @click="confirmImport()" />
				</template>
			</Dialog>
		</div>
	</div>
</template>

<script>

import wRules from '../service/xlsxParser/parseInRules/productCatalogParseRules.js'
import FlexiAPI from '../service/flexiAPI/flexiAPI.js';
import XLSX from 'xlsx'

// Import components
import ProcessXLSX from '../components/ProcessXLSX.vue'
import IconTabHeader from '../components/IconTabHeader.vue'
import {
	ImportAdresarTable,
	ImportSkupinyTable,
	ImportZnackyTable,
	ImportTypyTable,
	ImportRadyTable,
	ImportCenikTable
} from '../components/import-pricelist/index.js'

export default {
	data() {
		return {
			workbookRules: wRules,
			workbook: {
				adresar: null,
				typ: null,
				rada: null,
				skupina: null,
				znacka: null,
				cenik: null
			},
			flexi: {
				adresar: null,
				typy: null,
				rady: null,
				skupiny: null,
				znacky: null,
				cenik: null
			},
			processingProgress: 0.0,
			toImportTotal: 0,
			importCounter: 0,
			status: 'empty',
			importDialog: false,
			publicPath: process.env.BASE_URL
		}
	},
	computed: {
		importProgress() {
			const tmp = this.toImportTotal ? this.importCounter / this.toImportTotal : 1
			return Math.round(tmp*10000)/100
		},

		toImport() {
			const ret = [{
				evidence: 'Products',
				columns: {
					importFile: this.workbook.cenik.length,
					new: this.workbook.cenik.filter(a => a._status === 'OK').length,
					error: this.workbook.cenik.filter(a => a._status === 'E').length,
					duplicit: this.workbook.cenik.filter(a => a._status === 'D').length,
					update: this.workbook.cenik.filter(a => a._status === 'U').length,
					selected: this.workbook.cenik.filter(a => a._status === 'U' && a._selected).length,
					flexi: this.flexi.cenik.length
				}
			}]

			if (this.workbook.adresar instanceof Array) {
				ret.push({
					evidence: 'Address Book',
					columns: {
						importFile: this.workbook.adresar.length,
						new: this.workbook.adresar.filter(a => a._status === 'OK').length,
						error: this.workbook.adresar.filter(a => a._status === 'E').length,
						duplicit: this.workbook.adresar.filter(a => a._status === 'D').length,
						update: this.workbook.adresar.filter(a => a._status === 'U').length,
						selected: this.workbook.adresar.filter(a => a._status === 'U' && a._selected).length,
						flexi: this.flexi.adresar.length
					}
				})
			}

			if (this.workbook.znacka instanceof Array) {
				ret.push({
					evidence: 'Brands',
					columns: {
						importFile: this.workbook.znacka.length,
						new: this.workbook.znacka.filter(a => a._status === 'OK').length,
						error: this.workbook.znacka.filter(a => a._status === 'E').length,
						duplicit: this.workbook.znacka.filter(a => a._status === 'D').length,
						update: this.workbook.znacka.filter(a => a._status === 'U').length,
						selected: this.workbook.znacka.filter(a => a._status === 'U' && a._selected).length,
						flexi: this.flexi.znacky.length
					}
				})
			}

			if (this.workbook.skupina instanceof Array) {
				ret.push({
					evidence: 'Groups',
					columns: {
						importFile: this.workbook.skupina.length,
						new: this.workbook.skupina.filter(a => a._status === 'OK').length,
						error: this.workbook.skupina.filter(a => a._status === 'E').length,
						duplicit: this.workbook.skupina.filter(a => a._status === 'D').length,
						update: this.workbook.skupina.filter(a => a._status === 'U').length,
						selected: this.workbook.skupina.filter(a => a._status === 'U' && a._selected).length,
						flexi: this.flexi.skupiny.length
					}
				})
			}

			if (this.workbook.typ instanceof Array) {
				ret.push({
					evidence: 'Types',
					columns: {
						importFile: this.workbook.typ.length,
						new: this.workbook.typ.filter(a => a._status === 'OK').length,
						error: this.workbook.typ.filter(a => a._status === 'E').length,
						duplicit: this.workbook.typ.filter(a => a._status === 'D').length,
						update: this.workbook.typ.filter(a => a._status === 'U').length,
						selected: this.workbook.typ.filter(a => a._status === 'U' && a._selected).length,
						flexi: this.flexi.typy.length
					}
				})
			}

			if (this.workbook.rada instanceof Array) {
				ret.push({
					evidence: 'Lines',
					columns: {
						importFile: this.workbook.rada.length,
						new: this.workbook.rada.filter(a => a._status === 'OK').length,
						error: this.workbook.rada.filter(a => a._status === 'E').length,
						duplicit: this.workbook.rada.filter(a => a._status === 'D').length,
						update: this.workbook.rada.filter(a => a._status === 'U').length,
						selected: this.workbook.rada.filter(a => a._status === 'U' && a._selected).length,
						flexi: this.flexi.rady.length
					}
				})
			}

			return ret
		}
	},
	methods: {
		async workbookParsed(workbook) {

			this.workbook = workbook
			this.status = 'processing'

			this.processingProgress = 5.0

			this.flexi.cenik = await FlexiAPI.cenik.get(true)
			this.processingProgress = 40.0
			this.flexi.adresar = await FlexiAPI.adresar.get(true),
			this.processingProgress = 55.0
			this.flexi.znacky = await FlexiAPI.cenik.getZnacky(),
			this.processingProgress = 70.0
			this.flexi.skupiny = await FlexiAPI.cenik.getSkupiny(),
			this.processingProgress = 80.0
			this.flexi.typy = await FlexiAPI.cenik.getTypy(),
			this.processingProgress = 90.0
			this.flexi.rady = await FlexiAPI.cenik.getRady()
			this.processingProgress = 95.0

			this.processWorkbook()
			this.processingProgress = 100.0

			this.status = 'ready'
		},
		processWorkbook() {
			console.log('Workbook parsed')

			// Address Book
			const addrColumns = ['nazev', 'typVztahuK', 'ic', 'dic', 'stat', 'ulice',
				'mesto', 'psc', 'mobil', 'email', 'poznam']
			const znackyColumns = ['nazev']
			const typyColumns = ['nazev']
			const radyColumns = ['nazev', 'nazevA', 'znacka']
			const skupinyColumns = ['nazev', 'nazevA']
			const cenikColumns = ['nazev', 'vyrobce', 'znacka', 'skupZboz', 'typ', 'rada']

			this.bindFlexi(this.workbook.adresar, this.flexi.adresar, addrColumns)
			this.bindFlexi(this.workbook.znacka, this.flexi.znacky, znackyColumns)
			this.bindFlexi(this.workbook.typ, this.flexi.typy, typyColumns)
			this.bindFlexi(this.workbook.rada, this.flexi.rady, radyColumns, ['kod', 'znacka'])
			this.bindFlexi(this.workbook.skupina, this.flexi.skupiny, skupinyColumns)
			this.bindFlexi(this.workbook.cenik, this.flexi.cenik, cenikColumns)

			console.log('Workbook - Flexi records binded')

			// Create kod hash table and check new codes uniqueness
			const kodHashMap = new Map()
			for (const fprod of this.workbook.cenik) {
				if (kodHashMap.has(fprod.kod)) {
					const hprod = kodHashMap.get(fprod.kod)
					if (hprod !== fprod) {
						hprod._status = 'E'
						hprod._errors.push(new Error(`Product code '${hprod.kod}' is duplicit in this import. Change 'upresneni'`))
						fprod._status = 'E'
						fprod._errors.push(new Error(`Product code '${fprod.kod}' is duplicit in this import. Change 'upresneni'`))
					}
				} else {
					kodHashMap.set(fprod.kod, fprod)
				}
			}

			console.log('Product uniqueness checked')

			// Create hash table for ean uniqueness check
			const workbookEanHashMap = new Map()
			const flexiEanHashMap = new Map()

			for (const fprod of this.flexi.cenik) {
				fprod.allEans.forEach(ean => flexiEanHashMap.set(ean, fprod))
			}

			console.log('Flexi EAN Hash created')

			// Check EANs unique
			for (const wprod of this.workbook.cenik) {
				if (wprod.ean instanceof Array) {
					for (const ean of wprod.ean) {
						const wprd = workbookEanHashMap.get(ean)
						const fprd = flexiEanHashMap.get(ean)
						if ((!wprd || wprd === wprod) && (!fprd || fprd === wprod._flexi)) {
							workbookEanHashMap.set(ean, wprod)
							continue
						}

						if (wprd && wprd !== wprod) {
							wprod._status = 'E',
							wprod._errors.push(new Error(`Product '${wprod.kod}' has duplicit EAN (${ean}) with product ${wprd.kod}`))
							wprd._status = 'E',
							wprd._errors.push(new Error(`Product '${wprd.kod}' has duplicit EAN (${ean}) with product ${wprod.kod}`))
						}

						if (fprd && fprd !== wprod._flexi && wprd && fprd.kod !== wprd.kod) {
							wprod._status = 'E',
							wprod._errors.push(new Error(`Product '${wprod.kod}' has duplicit EAN (${ean}) with product ${fprd.kod}`))
						}

					}
				}
			}

			console.log('EANs checked')

			// Check attributes
			// Check mj and size
			// Check ID unique (on newly imported only)
			let ii = 0
			for (let produkt of this.workbook.cenik) {
				if (produkt._status === 'D') {
					// ATTRS
					if ((!produkt._flexi.znackaAttr || produkt.znacka !== produkt._flexi.znackaAttr.hodnota)
					|| (!produkt._flexi.typAttr || produkt.typ !== produkt._flexi.typAttr.hodnota)
					|| (!produkt._flexi.radaAttr || produkt.rada !== produkt._flexi.radaAttr.hodnota)
					|| (produkt.bqt !== produkt._flexi.bqt && produkt.bqt)
					|| (produkt.lqt !== produkt._flexi.lqt && produkt.lqt)
					|| (produkt.pqt !== produkt._flexi.pqt && produkt.pqt)) {
						produkt._status = 'U'
					}

					// MJ SIZE
					if (produkt.velikost_mj !== produkt._flexi.velikost_mj) {
						produkt._status = 'U'
					}
					if (produkt.velikost !== produkt._flexi.velikost) {
						produkt._status = 'U'
					}

					// EAN
					let eansChanged = false
					if (produkt.ean.length === produkt._flexi.allEans.length) {
						for (let i = 0; i < produkt.ean.length; i++) {
							if (produkt.ean[i] !== produkt._flexi.allEans[i]) {
								eansChanged = true
								break
							}
						}
					} else {
						eansChanged = true
					}
					if (eansChanged) {
						produkt._status = 'U'
					}
				}
			}

			// Relate pricelist
			for (let produkt of this.workbook.cenik) {
				this.checkRelation(produkt, 'typ', this.workbook.typ, this.flexi.typy)
				this.checkRelation(produkt, 'znacka', this.workbook.znacka, this.flexi.znacky)
				this.checkRelation(produkt, 'skupZboz', this.workbook.skupina, this.flexi.skupiny)
				this.checkRelation(produkt, 'rada', this.workbook.rada, this.flexi.rady)
				this.checkRelation(produkt, 'vyrobce', this.workbook.adresar, this.flexi.adresar)

				// Check Rady
				let wrada = null
				if (this.workbook.rada instanceof Array) {
					wrada = this.workbook.rada.find(wr => wr.kod === produkt.rada && wr.znacka === produkt.znacka)
				}
				const frada = this.flexi.rady.find(fr => fr.kod === produkt.rada && fr.znacka === produkt.znacka)

				if (!wrada && !frada) {
					produkt._status = 'E'
					produkt._errors.push(new Error(`Product '${produkt.kod}' has assigned unknown line (${produkt.rada}.${produkt.znacka})`))
				}
			}

			console.log('Relations checked')

			// Check Line duplicity
			if (this.workbook.rada instanceof Array) {
				for (let rada of this.workbook.rada) {
					if (rada._status === 'OK') {
						const found = this.workbook.rada.find(pr => pr.kod === rada.kod && pr.znacka === rada.znacka)
						if (found !== rada) {
							rada._status = 'E'
							rada._errors.push(new Error(`Line code '${rada.znacka}.${rada.kod}' is duplicit in this import.`))
						}
					}
				}
			}

			console.log('Line duplicity checked')

			// Check Brand duplicity
			if (this.workbook.znacka instanceof Array) {
				for (let znacka of this.workbook.znacka) {
					if (znacka._status === 'OK') {
						const found = this.workbook.znacka.find(pr => pr.kod === znacka.kod)
						if (found !== znacka) {
							znacka._status = 'E'
							znacka._errors.push(new Error(`Brand code '${znacka.kod}' is duplicit in this import.`))
						}
					}
				}
			}

			console.log('Brand duplicity checked')

			// Check Group duplicity
			if (this.workbook.skupina instanceof Array) {
				for (let skupina of this.workbook.skupina) {
					if (skupina._status === 'OK') {
						const found = this.workbook.skupina.find(pr => pr.kod === skupina.kod)
						if (found !== skupina) {
							skupina._status = 'E'
							skupina._errors.push(new Error(`Brand code '${skupina.kod}' is duplicit in this import.`))
						}
					}
				}
			}

			console.log('Group duplicity checked')

			// Check Type duplicity
			if (this.workbook.typ instanceof Array) {
				for (let typ of this.workbook.typ) {
					if (typ._status === 'OK') {
						const found = this.workbook.typ.find(pr => pr.kod === typ.kod)
						if (found !== typ) {
							typ._status = 'E'
							typ._errors.push(new Error(`Brand code '${typ.kod}' is duplicit in this import.`))
						}
					}
				}
			}

			console.log('Type duplicity checked')
			console.log('Workbook processing done')
		},

		checkRelation(produkt, key, workbook, flexi) {
			if (!produkt[key]) {
				return
			}

			let inWB = false
			if (workbook instanceof Array) {
				inWB = workbook.findIndex(t => t.kod === produkt[key]) >= 0
			}

			const inFL = flexi.findIndex(t => t.kod === produkt[key]) >= 0
			if (!inWB && !inFL) {
				produkt._status = 'E'
				produkt._errors.push(new Error(`Product '${produkt.kod}' has assigned unknown ${key} (${produkt[key]})`))
			}
		},

		bindFlexi(workbook, flexi, columns, keys) {
			if (!keys) {
				keys = ['kod']
			}
			if (workbook instanceof Array) {
				for (let imp of workbook) {
					const flex = flexi.find(i => {
						return keys.reduce((a, k) => {
							return a && i[k] === imp[k]
						}, true)
					}) || null
					imp._flexi = flex
					if (flex && imp._status !== 'E') {
						imp._status = 'D'
						if (!columns.reduce((a, key) => {
							return a && (imp[key] === flex[key] || ((!flex[key] || !flex[key].length) && !imp[key]))
						}, true)) {
							imp._status = 'U'
							imp._selected = false
						}
					}
				}
			}
		},

		clear() {
			this.workbook.adresar = null
			this.workbook.typ = null
			this.workbook.rada = null
			this.workbook.skupina = null
			this.workbook.znacka = null
			this.workbook.cenik = null

			this.flexi.adresar = null
			this.flexi.typy = null
			this.flexi.rady = null
			this.flexi.skupiny = null
			this.flexi.znacky = null
			this.flexi.cenik = null

			this.processingProgress = 0.0
			this.importCounter = 0
			this.toImportTotal = 0
			this.status = 'empty'
		},
		confirmClearing() {
			this.$confirm.require({
				message: 'Are you sure you want to proceed? All changes will be lost.',
				header: 'Clear loaded file',
				icon: 'pi pi-exclamation-triangle',
				accept: () => {
					this.clear()
				}
			})
		},
		importData() {
			this.importDialog = true

		},
		async confirmImport() {
			this.importDialog = false
			this.status = 'importing'

			let total = 0
			if (this.workbook.adresar instanceof Array) {
				total += this.workbook.adresar.filter(a => a._status === 'OK' || (a._status === 'U' && a._selected)).length
			}
			if (this.workbook.znacka instanceof Array) {
				total += this.workbook.znacka.filter(a => a._status === 'OK' || (a._status === 'U' && a._selected)).length
			}
			if (this.workbook.skupina instanceof Array) {
				total += this.workbook.skupina.filter(a => a._status === 'OK' || (a._status === 'U' && a._selected)).length
			}
			if (this.workbook.typ instanceof Array) {
				total += this.workbook.typ.filter(a => a._status === 'OK' || (a._status === 'U' && a._selected)).length
			}
			if (this.workbook.rada instanceof Array) {
				total += this.workbook.rada.filter(a => a._status === 'OK' || (a._status === 'U' && a._selected)).length
			}
			if (this.workbook.cenik instanceof Array) {
				total += this.workbook.cenik.filter(a => a._status === 'OK' || (a._status === 'U' && a._selected)).length
			}
			this.toImportTotal = total

			// Import adresar
			if (this.workbook.adresar instanceof Array) {
				for (let addr of this.workbook.adresar) {
					if (addr._status === 'OK' || (addr._status === 'U' && addr._selected)) {
						const entry = {
							kod: addr.kod,
							nazev: addr.nazev,
							ulice: addr.ulice,
							mesto: addr.mesto,
							psc: addr.psc,
							mobil: addr.mobil,
							email: addr.email,
							stat: addr.stat,
							ic: addr.ic,
							dic: addr.dic,
							typVztahuK: addr.typVztahuK,
							poznam: addr.poznam
						}
						try {
							const res = await FlexiAPI.adresar.post(entry)
							addr._status = 'D'
						} catch (e) {
							addr._status = 'E'
							addr._errors.push(new Error(`Partner ${entry.kod} import error: ${e}`))
						}

						this.importCounter++;
					}
				}
			}

			// Import brands
			if (this.workbook.znacka instanceof Array) {
				for (let zn of this.workbook.znacka) {
					if (zn._status === 'OK' || (zn._status === 'U' && zn._selected)) {
						const entry = {
							kod: zn.kod,
							nazev: zn.nazev,
							vsbKatalog: true
						}
						try {
							const res = await FlexiAPI.cenik.postZnacka(entry)
							zn._status = 'D'
						} catch (e) {
							zn._status = 'E'
							zn._errors.push(new Error(`Brand ${entry.kod} import error: ${e}`))
						}
						this.importCounter++;
					}
				}
			}

			// Import groups
			if (this.workbook.skupina instanceof Array) {
				for (let zn of this.workbook.skupina) {
					if (zn._status === 'OK' || (zn._status === 'U' && zn._selected)) {
						const entry = {
							kod: zn.kod,
							nazev: zn.nazev,
							nazevA: zn.nazevA
						}
						try {
							const res = await FlexiAPI.cenik.postSkupina(entry)
						} catch (e) {
							zn._status = 'E'
							zn._errors.push(new Error(`Product Group ${entry.kod} import error: ${e}`))
						}
						this.importCounter++;
					}
				}
			}

			// Import types
			if (this.workbook.typ instanceof Array) {
				for (let zn of this.workbook.typ) {
					if (zn._status === 'OK' || (zn._status === 'U' && zn._selected)) {
						const entry = {
							kod: zn.kod,
							nazev: zn.nazev,
							nazevA: zn.nazevA,
							vsbKatalog: true
						}
						try {
							const res = await FlexiAPI.cenik.postTyp(entry)
						} catch (e) {
							zn._status = 'E'
							zn._errors.push(new Error(`Product Type ${entry.kod} import error: ${e}`))
						}
						this.importCounter++;
					}
				}
			}

			// Import lines
			if (this.workbook.rada instanceof Array) {
				for (let zn of this.workbook.rada) {
					if (zn._status === 'OK' || (zn._status === 'U' && zn._selected)) {
						const entry = {
							kod: zn.kod,
							znacka: zn.znacka,
							nazev: zn.nazev,
							vsbKatalog: true
						}
						try {
							const res = await FlexiAPI.cenik.postRada(entry)
						} catch (e) {
							zn._status = 'E'
							zn._errors.push(new Error(`Product Line ${entry.kod} import error: ${e}`))
						}
						this.importCounter++;
					}
				}
			}

			// Import pricelist
			if (this.workbook.cenik instanceof Array) {
				for (let prod of this.workbook.cenik) {
					if (prod._status === 'OK' || (prod._status === 'U' && prod._selected)) {
						let entry = {
							kod: prod.kod,
							nazev: prod.nazev,
							vyrobce: prod.vyrobce,
							znacka: prod.znacka,
							typ: prod.typ,
							rada: prod.rada,
							skupZboz: prod.skupZboz,
							eanKod: prod.ean[0],
							allEans: prod.ean,
							velikost: prod.velikost,
							velikost_mj: prod.velikost_mj,
							bqt: prod.bqt || null,
							lqt: prod.lqt || null,
							pqt: prod.pqt || null
						}

						if (prod._status === 'D') {
							entry = {
								kod: prod.kod
							}
						}

						try {
							const res = await FlexiAPI.cenik.postWithOneDodavatel(entry)
							prod._status = 'D'
						} catch (e) {
							prod._status = 'E'
							prod._errors.push(new Error(`Product ${entry.kod} import error: ${e}`))
						}
						this.importCounter++;
					}
				}
			}

			this.status = 'done';
		},

		downloadFailed() {
			const wb = XLSX.utils.book_new()

			const entries = {
				cenik: this.workbook.cenik ? this.workbook.cenik.filter(it => it._status === 'E') : [],
				adresar: this.workbook.adresar ? this.workbook.adresar.filter(it => it._status === 'E') : [],
				znacka: this.workbook.znacka ? this.workbook.znacka.filter(it => it._status === 'E') : [],
				skupina: this.workbook.skupina ? this.workbook.skupina.filter(it => it._status === 'E') : [],
				typ: this.workbook.typ ? this.workbook.typ.filter(it => it._status === 'E') : [],
				rada: this.workbook.rada ? this.workbook.rada.filter(it => it._status === 'E') : []
			}

			if (entries.cenik.length) {
				const cenikWs = XLSX.utils.aoa_to_sheet([['Výrobce', 'Značka', 'Skupina', 'Typ', 'EAN', 'Název', 'Řada', 'Velikost', 'Jednotka', 'Upřesnění', 'Box QTY', 'Layer QTY', 'Palet QTY', 'Kod', 'Chyba'],
				...entries.cenik.map(prd => {
					return [
						prd.vyrobce,
						prd.znacka,
						prd.skupZboz,
						prd.typ,
						prd.ean.join('-'),
						prd.nazev,
						prd.rada,
						prd.velikost,
						prd.velikost_mj,
						prd.upresneni,
						prd.bqt,
						prd.lqt,
						prd.pqt,
						prd.kod,
						prd._errors.join('\n')
					]
				})])
				XLSX.utils.book_append_sheet(wb, cenikWs, "Ceník")
			}

			if (entries.adresar.length) {
				const adresarWs = XLSX.utils.aoa_to_sheet([['Název', 'Kód', 'Typ', 'IČ', 'DIČ', 'Stát', 'Ulice a čp', 'Město', 'PSČ', 'Email', 'Mobil', 'Poznámka', 'Chyba'],
				...entries.adresar.map(prd => {
					return [
						prd.nazev,
						prd.kod,
						prd.typ,
						prd.ic,
						prd.dic,
						prd.stat,
						prd.ulice,
						prd.mesto,
						prd.psc,
						prd.email,
						prd.mobil,
						prd.poznam,
						prd._errors.join('\n')
					]
				})])
				XLSX.utils.book_append_sheet(wb, adresarWs, "Adresář")
			}

			if (entries.znacka.length) {
				const znackaWs = XLSX.utils.aoa_to_sheet([['Název', 'Kód', 'Chyba'],
				...entries.znacka.map(prd => {
					return [
						prd.nazev,
						prd.kod,
						prd._errors.join('\n')
					]
				})])
				XLSX.utils.book_append_sheet(wb, znackaWs, "Značka")
			}

			if (entries.skupina.length) {
				const skupinaWs = XLSX.utils.aoa_to_sheet([['Název', 'Název ENG', 'Kód', 'Chyba'],
				...entries.skupina.map(prd => {
					return [
						prd.nazev,
						prd.nazevA,
						prd.kod,
						prd._errors.join('\n')
					]
				})])
				XLSX.utils.book_append_sheet(wb, skupinaWs, "Skupina")
			}

			if (entries.typ.length) {
				const typWs = XLSX.utils.aoa_to_sheet([['Název', 'Název ENG', 'Kód', 'Chyba'],
				...entries.typ.map(prd => {
					return [
						prd.nazev,
						prd.nazevA,
						prd.kod,
						prd._errors.join('\n')
					]
				})])
				XLSX.utils.book_append_sheet(wb, typWs, "Typ")
			}

			if (entries.rada.length) {
				const radaWs = XLSX.utils.aoa_to_sheet([['Značka', 'Nazev', 'Kód', 'Chyba'],
				...entries.rada.map(prd => {
					return [
						prd.znacka,
						prd.nazev,
						prd.kod,
						prd._errors.join('\n')
					]
				})])
				XLSX.utils.book_append_sheet(wb, radaWs, "Řada")
			}

			const date = (new Date()).toISOString().replace('T', '_').replace(':','-').replace(':', '-').substring(0,19)
			XLSX.writeFile(wb, `${date}_failed_import_cenik.xlsx`);
		},

		toggleUpdate(row) {
			row._selected = !row._selected
		}
	},
	components: {
		ProcessXLSX,
		IconTabHeader,
		ImportAdresarTable,
		ImportSkupinyTable,
		ImportZnackyTable,
		ImportTypyTable,
		ImportRadyTable,
		ImportCenikTable
	}
}
</script>

<style scoped>

a.p-button {
	cursor: pointer;
}

h1 {
	font-size: 2rem;
}

</style>
