<template>
  <i :class="iconClass"></i>&nbsp;
  <span
    v-if="disabled"
    v-tooltip="'{{label}} sheet not found in XLSX file'"
    >{{label}}</span>
  <span v-if="!disabled">{{label}}</span>
</template>

<script>
export default {
  props: ['icon', 'label', 'disabled'],
  data() {
    return {}
  },
  computed: {
    iconClass() {
      return 'pi ' + this.icon
    }
  }
}
</script>

<style scoped>
</style>
